import React, { FC, JSX, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router";
import { NavigateFunction, useNavigate } from "react-router-dom";
import DashboardPage from "./pages/DashboardPage";
import LoginPage from "./pages/LoginPage";
import StubPage from "./pages/StubPage/StubPage";
import { AppStateType } from "./reducers/mainReducer";
import MainLayout from "./components/MainLayout/MainLayout";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import NewPasswordPage from "./pages/NewPasswordPage";

const App: FC = (): JSX.Element => {
  const { authToken } = useSelector((state: AppStateType) => state.account);

  const navigate: NavigateFunction = useNavigate();
  const params = useLocation();

  const [isResetFormOpened, setResetFormOpened] = useState<boolean>(false);

  useEffect(() => {
    if (!authToken
      && !isResetFormOpened
      && params.pathname !== "/confirm-password"
      && params.pathname !== "/reset-password"
      && params.pathname !== "/registration"
    ) {
      navigate("/login");
    }
  }, [navigate, authToken, isResetFormOpened, params.pathname]);

  return (
    <MainLayout>
      <Routes>
        <Route path="/" element={<StubPage />} />
        {/* Форма входа */}
        <Route
          path="/login"
          element={<LoginPage />}
        />
        {/* Форма сброса пароля */}
        <Route
          path="/reset-password"
          element={<ResetPasswordPage />}
        />
        {/* Форма создания нового пароля */}
        <Route
          path="/confirm-password"
          element={<NewPasswordPage />}
        />
        {/* Форма регистрации */}
        <Route
          path="/registration"
        />
        {authToken && (
          <Route path="/dashboard" element={<DashboardPage />} />
        )}
      </Routes>
    </MainLayout>
  );
};

export default App;
