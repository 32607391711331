import { combineReducers } from "redux";
import account from "./account.reducer";

const mainReducer = combineReducers({
  account,
});

type MainReducerType = typeof mainReducer;
export type AppStateType = ReturnType<MainReducerType>;

export default mainReducer;
