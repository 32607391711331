import * as process from "process";
import axios from "../utils/axios";

import { LoginDataType } from "app/types";

const API_BASE: string | undefined = process.env.REACT_APP_API_BASE_URL;
const AUTH_URL: string | undefined = process.env.REACT_APP_AUTH_URL;
const realm: string | undefined = process.env.REACT_APP_REALM_DEV;
const clientSecret: string | undefined = process.env.REACT_APP_CLIENT_SECRET_USER;
const clientId: string = "bk-market-dev";
const grantType: string = "password";
const scope: string = "openid";

// Авторизация
export const login = async (username: string, password: string): Promise<any> => {
  const loginData: LoginDataType = {
    client_id: clientId,
    grant_type: grantType,
    client_secret: clientSecret,
    scope,
    username,
    password
  };

  return axios.POST_LOGIN(API_BASE, `auth/realms/${realm}/protocol/openid-connect/token`, loginData);
};

// Refresh
export const updateToken = async (refreshToken: string | null): Promise<any> => {
  const loginData: LoginDataType = {
    client_id: clientId,
    grant_type: grantType,
    client_secret: clientSecret,
    scope,
    refresh_token: refreshToken,
  };

  return axios.POST_LOGIN(API_BASE, `auth/realms/${realm}/protocol/openid-connect/token`, loginData);
};

// Отзыв токена
export const logout = async (token: string | null): Promise<any> => {
  const loginData = {
    token
  };

  return axios.POST_LOGIN(API_BASE, `auth/realms/${realm}/protocol/openid-connect/revoke`, loginData);
};

// Получение данных об авторизованном пользователе
export const fetchUserInfo = async (): Promise<any> => {
  return axios.POST_LOGIN(API_BASE, `auth/realms/${realm}/protocol/openid-connect/userinfo`);
};
