import React, { FC, JSX, useEffect, useState } from "react";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import { useDispatch, useSelector} from "react-redux";
import { Button, Form, Input } from "antd";
import { AppStateType } from "../../../reducers/mainReducer";
import { AppDispatch } from "../../../store/store";
import { login } from "../../../actions/account.actions";
import FormTitle from "../FormTitle/FormTitle";

import css from "./LoginForm.module.css";

interface ILoginFormProps {}

const LoginForm: FC<ILoginFormProps> = (): JSX.Element => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate: NavigateFunction = useNavigate();

  const { authToken, error } = useSelector((state: AppStateType) => state.account);

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  useEffect((): void => {
    if (authToken) {
      navigate("/dashboard");
    }
  }, [authToken, navigate]);

  useEffect(() => {
    setIsDisabled(!email || !password);
  }, [password, email]);

  const handleSubmit = () => {
    dispatch(login(email, password));
  };

  return (
    <div>
      <FormTitle title="Вход" subtitle="С возвращением! Введите ваши данные для авторизации."/>
      <Form onFinish={handleSubmit}>
        <Form.Item
          name="email"
          validateStatus={error || !email ? "error" : ""}
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите ваш email!"
            },
            {
              type: "email",
              message: "Введите email в формате email@mail.ru"
            }
          ]}
        >
          <Input
            className={css.loginFormInput}
            placeholder="Email"
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          name="password"
          validateStatus={error || !password ? "error" : ""}
        >
          <Input.Password
            className={css.loginFormInput}
            placeholder="Пароль"
            onInput={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
          />
          <span className="danger-color block">
            {error ? "Неверный логин или пароль" : ""}
            {!password ? "Пожалуйста, введите ваш пароль!" : ""}
          </span>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            size="large"
            disabled={isDisabled}
            htmlType="submit"
            className="bg-blue-500"
          >
            Войти
          </Button>
        </Form.Item>
      </Form>
      <Link to="/reset-password" className="blue-color mb-6 inline-block">Не помню пароль</Link>
      {/*Пока регистрации через интерфейс нет, скрываем кнопку*/}
      {/*<p>*/}
      {/*  У вас нет аккаунта?*/}
      {/*  <Link to="/registration" className="blue-color"> Зарегистрируйтесь</Link>*/}
      {/*</p>*/}
    </div>
  );
};

export default LoginForm;
