import React, { FC, JSX, useEffect } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AppStateType } from "../../reducers/mainReducer";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

interface IStubPageProps {}

const StubPage: FC<IStubPageProps> = (): JSX.Element => {
  const { authToken } = useSelector((state: AppStateType) => state.account);

  const navigate: NavigateFunction = useNavigate();

  useEffect((): void => {
    if (!authToken) {
      navigate("/login");
    } else {
      navigate("/dashboard");
    }
  }, [authToken, navigate]);

  const antIcon: JSX.Element = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
    <Spin indicator={antIcon} />
  );
};

export default StubPage;
