import React, { FC, useState, useEffect } from "react";
import { Button, Form } from "antd";
import type { FormInstance } from "antd";

interface ISubmitButtonProps {
  form: FormInstance,
  text: string,
  onClick: () => void,
}

const SubmitButton: FC<ISubmitButtonProps> = ({ form, text, onClick }) => {
  const [submittable, setSubmittable] = useState<boolean>(false);

  const values = Form.useWatch([], form);

  useEffect(() => {
    form.validateFields({ validateOnly: true }).then(
      () => {
        setSubmittable(true);
      },
      () => {
        setSubmittable(false);
      },
    );
  }, [values, form]);

  return (
    <Button
      type="primary"
      size="large"
      className="bg-blue-500"
      htmlType="submit"
      disabled={!submittable}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default SubmitButton;