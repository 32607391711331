import React, { JSX, FC } from "react";
import { Form, Input } from "antd";
import css from "./style.module.css";
import SubmitButton from "../../SubmitButton/SubmitButton";
import FormTitle from "../FormTitle/FormTitle";

interface IResetPasswordFormProps { }

const ResetPasswordForm: FC<IResetPasswordFormProps> = (): JSX.Element => {
  const [form] = Form.useForm();

  return (
    <div>
      <FormTitle
        title="Восстановление пароля"
        subtitle="Введите адрес электронной почты, который вы используете для входа в аккаунт."
      />
      <Form form={form} name="passwordRecovery">
        <Form.Item
          name="email"
          rules={[
            {
              type: "email",
              message: "Введите email в формате email@mail.ru!",
            },
            {
              required: true,
              message: "Пожалуйста, введите ваш email!"
            }
          ]}
        >
          <Input
            className={css.emailInput}
            placeholder="Email"
          />
        </Form.Item>
        <Form.Item>
          <SubmitButton form={form} text="Сбросить пароль" onClick={() => { }} />
        </Form.Item>
      </Form>
    </div>
  );
};

export default ResetPasswordForm;