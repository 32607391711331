import React, { FC, ReactNode, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { MenuInfo } from "rc-menu/lib/interface";
import { logout } from "actions/account.actions";
import { Layout, Menu, Badge, MenuProps } from "antd";

import { ReactComponent as MainPageIcon } from "../../assets/icons/main_icon.svg";
import { ReactComponent as ProductsPageIcon } from "../../assets/icons/shopping_cart.svg";
import { ReactComponent as AssemblyTasksPageIcon } from "../../assets/icons/assembly_tasks_icon.svg";
import { ReactComponent as WarehousesPageIcon } from "../../assets/icons/geo_icon.svg";
import { ReactComponent as PriceManagementPageIcon } from "../../assets/icons/ruble_square.svg";
import { ReactComponent as BalanceManagementPageIcon } from "../../assets/icons/chart_bar_square.svg";
import { ReactComponent as HelpPageIcon } from "../../assets/icons/help_circle.svg";
import { ReactComponent as LogoutIcon } from "../../assets/icons/logout_icon.svg";
import { ReactComponent as Logo } from "../../assets/img/logo_sidebar.svg";

import css from "./style.module.css";

interface MenuItemProps {
  key: string;
  icon: ReactNode;
  label: string | ReactNode;
  url: string;
  onClick?: () => void;
};

interface ISidebar { }

const Sidebar: FC<ISidebar> = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [current, setCurrent] = useState<string>("");

  const location = useLocation();

  useEffect(() => {
    const currentMenu: string = MAIN_MENU_ITEMS
      .find((item) => item.url === location.pathname)?.key
      || "";

    setCurrent(currentMenu);
  }, [location]);

  // TODO: Тут логика счетчика для "Сборочных заданий"
  const getTasksCount = (): number => {
    return Math.round(Math.random() * 100);
  };

  const handleSelectItem = (e: MenuInfo): void => {
    setCurrent(e.key);
  };

  const renderMenuItem: FC<MenuItemProps> = ({
    key,
    icon,
    label,
    url,
    onClick
  }) => {
    return (
      <Menu.Item
        key={key}
        icon={icon}
        className={`${css.menu_item} ${key === current ? css.selected : ""}`}
      >
        <Link to={url} onClick={() => onClick()}>{label}</Link>
      </Menu.Item>
    );
  };

  const handleLogout = (): void => {
    dispatch(logout());
  };

  const MAIN_MENU_ITEMS: MenuItemProps[] = [
    {
      key: "route-main",
      icon: <MainPageIcon className={css.menu_icon} />,
      label: "Главная",
      url: "/"
    },
    {
      key: "route-products",
      icon: <ProductsPageIcon className={css.menu_icon} />,
      label: "Товары",
      url: "/products"
    },
    {
      key: "route-assembly-tasks",
      icon: <AssemblyTasksPageIcon className={css.menu_icon} />,
      label: (
        <span className={css.badge}>
          Сборочные задания
          <Badge
            style={{
              color: "var(--main-badge-color)",
              backgroundColor: "var(--main-badge-bg-color)"
            }}
            className={css.counter}
            count={getTasksCount()}
          />
        </span>
      ),
      url: "/assembly-tasks"
    },
    {
      key: "route-warehouses",
      icon: <WarehousesPageIcon className={css.menu_icon} />,
      label: "Склады",
      url: "/warehouses"
    },
    {
      key: "route-price-management",
      icon: <PriceManagementPageIcon className={css.menu_icon} />,
      label: "Управление ценами",
      url: "/price-management"
    },
    {
      key: "route-balance-management",
      icon: <BalanceManagementPageIcon className={css.menu_icon} />,
      label: "Управление остатками",
      url: "/balance-management"
    },
  ];

  const BOTTOM_MENU_ITEMS: MenuItemProps[] = [
    {
      key: "route-help",
      icon: <HelpPageIcon className={css.menu_icon} />,
      label: "Помощь",
      url: "/help"
    },
    {
      key: "route-logout",
      icon: <LogoutIcon className={css.menu_icon} />,
      label: "Выйти",
      url: "/login",
      onClick: handleLogout
    }
  ];

  return (
    <Layout.Sider
      theme="light"
      className={css.left_sidebar}
      width="16rem"
    >
      <div>
        {/* Logo */}
        <Link to="/"><Logo className={css.sidebar_logo} /></Link>

        {/* Menu */}
        <Menu
          theme="light"
          mode="inline"
          defaultSelectedKeys={[current]}
          className={css.sidebar_menu}
          selectedKeys={[current]}
          onClick={handleSelectItem}
        >
          {MAIN_MENU_ITEMS.map(renderMenuItem)}
        </Menu>
      </div>

      {/* Help button */}
      <Menu
        theme="light"
        mode="inline"
        className={`${css.sidebar_menu} mb-3`}
        selectedKeys={[current]}
        onClick={handleSelectItem}
      >
        {BOTTOM_MENU_ITEMS.map(renderMenuItem)}
      </Menu>
    </Layout.Sider>
  );
};

export default Sidebar;
