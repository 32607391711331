import React, { JSX, FC } from "react";
import { Form, Input } from "antd";
import css from "./style.module.css";
import FormTitle from "../FormTitle/FormTitle";
import SubmitButton from "../../SubmitButton/SubmitButton";

interface INewPasswordFormProps { }

const NewPasswordForm: FC<INewPasswordFormProps> = (): JSX.Element => {
  const [form] = Form.useForm();

  return (
    <div className={css.form}>
      <FormTitle
        title="Восстановление пароля"
        subtitle="
          Придумайте новый пароль. 
          Он должен быть не менее 8 символов, 
          содержать прописные и заглавные буквы и цифры."
      />
      <Form form={form} name="passwordRecovery">
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите ваш пароль"
            }
          ]}
        >
          <Input.Password
            className={css.passwordInput}
            placeholder="Пароль"
          />
        </Form.Item>

        <Form.Item
          name="confirm"
          dependencies={["password"]}
          rules={[
            {
              required: true,
              message: "Пожалуйста, введите ваш пароль"
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Пароли не совпадают"));
              },
            }),
          ]}
        >
          <Input.Password
            className={css.passwordInput}
            placeholder="Придумайте пароль"
          />
        </Form.Item>
        <Form.Item>
          <SubmitButton form={form} text="Установить пароль" onClick={() => { }} />
        </Form.Item>
      </Form>
    </div>
  );
};

export default NewPasswordForm;
