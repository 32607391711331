import axios from "axios";
import { ThunkAction } from "redux-thunk";
import { AppStateType } from "../reducers/mainReducer";

import {
  LOGOUT,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILURE,
  FETCH_USER_INFO_SUCCESS,
  FETCH_USER_INFO_FAILURE,
} from "../constants/actionTypes";
import { UserInfoType } from "app/types";

import asyncLocalStorage from "../utils/asyncLocalStorage";
import * as AccountAPI from "../api/account.api";

export type AccountActionsTypes =
  | LogoutType
  | LoginSuccessType
  | LoginFailureType
  | RefreshTokenSuccessType
  | RefreshTokenFailureType
  | FetchUserInfoSuccessType
  | FetchUserInfoFailureType

// Авторизация
type LoginSuccessType = {
  type: typeof LOGIN_SUCCESS;
  payload: {
    access_token: string;
    refresh_token: string;
  };
};

export const loginSuccess = (access_token: string, refresh_token: string): LoginSuccessType => ({
  type: LOGIN_SUCCESS,
  payload: {
    access_token,
    refresh_token
  }
});

type LoginFailureType = {
  type: typeof LOGIN_FAILURE;
  payload: {
    error: string;
  };
};

export const loginFailure = (error: string): LoginFailureType => ({
  type: LOGIN_FAILURE,
  payload: {
    error
  }
});

export const login = (
  username: string,
  password: string
): ThunkAction<
  Promise<void>,
  AppStateType,
  undefined,
  AccountActionsTypes
> => async (dispatch) => {
  try {
    const response = await AccountAPI.login(username, password);

    asyncLocalStorage
      .setItem("authToken", JSON.stringify(response.data.access_token))
      .then((): void => {
        dispatch(loginSuccess(response.data.access_token, response.data.refresh_token));
        axios.defaults.headers.common.Authorization = response.data.access_token;
      });

    localStorage.setItem("refreshToken", JSON.stringify(response.data.refresh_token));

  } catch (error: any) {
    dispatch(loginFailure(error.message));
  }
};

// Обновление токена через refresh_token
type RefreshTokenSuccessType = {
  type: typeof REFRESH_TOKEN_SUCCESS;
  payload: {
    access_token: string;
    refresh_token: string;
  };
};

export const refreshTokenSuccess = (access_token: string, refresh_token: string): RefreshTokenSuccessType => ({
  type: REFRESH_TOKEN_SUCCESS,
  payload: {
    access_token,
    refresh_token
  }
});

type RefreshTokenFailureType = {
  type: typeof REFRESH_TOKEN_FAILURE;
  payload: {
    error: string;
  };
};

export const refreshTokenFailure = (error: string): RefreshTokenFailureType => ({
  type: REFRESH_TOKEN_FAILURE,
  payload: {
    error
  }
});

export const fetchNewToken = (refreshToken: string | null):
  ThunkAction<
    Promise<void>,
    AppStateType,
    undefined,
    AccountActionsTypes
  > => async (dispatch) => {
  try {
    const response = await AccountAPI.updateToken(refreshToken);

    asyncLocalStorage
      .setItem("authToken", JSON.stringify(response.data.access_token))
      .then((): void => {
        dispatch(refreshTokenSuccess(response.data.access_token, response.data.refresh_token));
        axios.defaults.headers.common.Authorization = response.data.access_token;
      });

    localStorage.setItem("refreshToken", JSON.stringify(response.data.refresh_token));

  } catch (error: any) {
    dispatch(refreshTokenFailure(error.message));
    dispatch(logout());
  }
};

// Получение информации об авторизованном пользователе по токену
type FetchUserInfoSuccessType = {
  type: typeof FETCH_USER_INFO_SUCCESS;
  payload: {
    userInfo: UserInfoType;
  };
};

export const fetchUserInfoSuccess = (userInfo: UserInfoType): FetchUserInfoSuccessType => ({
  type: FETCH_USER_INFO_SUCCESS,
  payload: {
    userInfo
  }
});

type FetchUserInfoFailureType = {
  type: typeof FETCH_USER_INFO_FAILURE;
  payload: {
    error: string;
  };
};

export const fetchUserInfoFailure = (error: string): FetchUserInfoFailureType => ({
  type: FETCH_USER_INFO_FAILURE,
  payload: {
    error
  }
});

export const fetchUserInfo = ():
  ThunkAction<
    Promise<void>,
    AppStateType,
    undefined,
    AccountActionsTypes
  > => async (dispatch) => {
  try {
    const response = await AccountAPI.fetchUserInfo();

    dispatch(fetchUserInfoSuccess(response.data));
  } catch (error: any) {
    dispatch(fetchUserInfoFailure(error.message));
  }
};

// Деавторизация
type LogoutType = {
  type: typeof LOGOUT;
};

export const logout = (): LogoutType => {
  localStorage.removeItem("authToken");
  localStorage.removeItem("refreshToken");

  return { type: LOGOUT };
};
